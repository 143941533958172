<template>
  <top />

  <tags
    v-for="(item, index) in list"
    :key="index"
    :data="item"
    :reverse="index % 2"
  >
    <template v-if="index === 0" v-slot:title>
      <div class="f1">RFID标签产品</div>
    </template>
    <template v-if="index === 0" v-slot:info>
      <div class="f2 m-f2">
        将射频识别（RFID）芯片与产品标签相结合，不仅使您的产品不可复制，更能有效进行产品追踪，从而极大提高仓储、物流、零售的效率，迈入智能制造与智能零售的新纪元。
      </div>
    </template>
  </tags>
  <common-footer></common-footer>
</template>
<script>
import top from "./top";
import tags from "./tags";
import CommonFooter from "@components/footer.vue";
import { mapMutations } from "vuex";
export default {
  components: {
    top,
    tags,
    CommonFooter,
  },
  data() {
    return {
      list: [
        {
          image: require("@/assets/tags/tags/1.png"),
          name: "RFID/二维码标签",
          info: "使用二维码技术让标签栩栩如生，创造新的数字营销机会来吸引客户并加强品牌忠诚度。",
          merit: [
            "缩小的标签尺寸提供成本效益并支持可持续性",
            "在不影响交货时间或成本的情况下支持最后一分钟的内容更改",
            "不需要高技术能力来实施",
          ],
          bg: require("@/assets/tags/tags/bg.png"),
          icon: require("@/assets/tags/tags/icon1.png"),
        },
        {
          image: require("@/assets/tags/tags/2.png"),
          name: "价格吊牌标签",
          info: "吊牌及价格牌展示了大量产品或服裝信息，针对采用RFID的品牌，我们的吊牌及价格牌可以汇入RFID嵌体，亦可制为贴纸附于吊牌表面。",
          merit: [
            "采用各种可持续材料及形式",
            "确保各地品质及颜色始终如一",
            "支持RFID及二维码技术",
          ],
          icon: require("@/assets/tags/tags/icon2.png"),
        },
        {
          image: require("@/assets/tags/tags/3.png"),
          name: "织唛标签",
          info: "无论是单纯用于品牌推广，还是包含更详细的产品信息，织唛标签都能更好展现品牌形象。",
          merit: [
            "使用最优质的纱线和材料",
            "织唛生产工艺中包含多种织边和整理技术",
            "专家团队选择契合品牌形象与产品类型的款式",
          ],
          bg: require("@/assets/tags/tags/bg1.png"),
          icon: require("@/assets/tags/tags/icon3.png"),
        },
        {
          image: require("@/assets/tags/tags/4.png"),
          name: "标签贴纸",
          info: "采用定制化的贴纸满足您所有产品及包装需要，让贴纸标签的应用更轻松简单。",
          merit: [
            "定制化形状及大小",
            "永久性或可移除不干胶",
            "支持RFID及二维码技术",
          ],
          icon: require("@/assets/tags/tags/icon4.png"),
        },
        {
          image: require("@/assets/tags/tags/5.png"),
          name: "电子智能包装盒",
          info: "从环保包装到电子商务包装和定制零售包装，常达都能满足您的需求。结合我们广泛的产品知识和行业经验，我们的专家 Trimologist 能够针对您的所有包装需求提出建议。我们能够留下持久的印象并确保结构和质量，同时最大限度地减少对环境的影响。",
          merit: [
            "提供可持续包装方案",
            "确保各地品质及颜色始终如一",
            "支持RFID及二维码技术",
          ],
          bg: require("@/assets/tags/tags/bg1.png"),
          icon: require("@/assets/tags/tags/icon5.png"),
        },
        {
          image: require("@/assets/tags/tags/6.png"),
          name: "热转印",
          info: "如果您想通过其他方式展示您的品牌，热转印标签将是一个不错的选择。随着运动服和贴身内衣的兴起，客户现在正在积极寻找无标签品牌。",
          merit: [
            "耐用、灵活及舒适",
            "支持RFID及二维码技术",
            "兼容多种颜色及设计复杂的图像及文字",
          ],
          icon: require("@/assets/tags/tags/icon6.png"),
        },
        {
          image: require("@/assets/tags/tags/7.png"),
          name: "辅料",
          info: "我们的辅料有多种类别多种材料可供选择，在如何创作创新艺术品方面没有限制，我们专注于提供适合您的辅料",
          merit: [
            "提供符合GRS标准的回收材料",
            "提供耐用性和“无动物”替代传统皮革贴片",
            "采用各种可持续型材料和方式",
          ],
          bg: require("@/assets/tags/tags/bg1.png"),
          icon: require("@/assets/tags/tags/icon7.png"),
        },
      ],
    };
  },
  methods: {
    ...mapMutations([
      "scrollToSection",
      "setHeaderColorBlack",
      "setHeaderColorWhite",
      "setheaderTopBtnWhite",
    ]),
  },
  mounted() {
    this.scrollToSection(this.$route.hash);
    this.setHeaderColorWhite();
    this.setheaderTopBtnWhite(true);
  },
  unmounted() {
    this.setHeaderColorBlack();
    this.setheaderTopBtnWhite(false);
  },
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  @if ($searchWidth<1100) {
    .m-f1 {
      margin-bottom: -3.2rem;
    }
  }
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
