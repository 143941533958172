<template>
  <div class="home-top">
    <img class="pc" :src="require('@/assets/tags/bg.png')" />
    <img class="moblie" :src="require('@/assets/tags/m-bg.png')" />
    <div class="text">
      <div class="t1">产品描述</div>
      <div class="t2">根据您的需求来供应、定制化标签</div>
      <a
        class="btn"
        target="_blank"
        href="https://clothing.cirteklink.com/shop/#/login"
        >服装门店系统试用</a
      >
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .home-top {
    position: relative;

    @if ($searchWidth<1100) {
      margin-top: 2.3rem;
    }

    img {
      width: 100%;
      display: block;
    }
  }

  .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    .t1 {
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #ffffff;

      @if ($searchWidth<1100) {
        font-size: 1rem;
      } @else {
        font-size: 0.6rem;
      }
    }

    .t2 {
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #ffffff;

      @if ($searchWidth<1100) {
        font-size: 0.7rem;
        margin: 0.7rem 0 1.25rem;
      } @else {
        font-size: 0.3rem;
        margin: 0.3rem 0 0.5rem;
      }
    }

    .btn {
      // height: 0.64rem;
      // line-height: 0.64rem;
      background: #5ec7b9;
      // border-radius: 0.32rem;
      // font-size: 0.24rem;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #ffffff;
      display: inline-block;
      // padding: 0 0.26rem;
      text-decoration: none;

      @if ($searchWidth<1100) {
        height: 2rem;
        line-height: 2rem;
        font-size: 0.7rem;
        border-radius: 1rem;
        padding: 0 1.2rem;
      } @else {
        height: 0.64rem;
        line-height: 0.64rem;
        font-size: 0.24rem;
        border-radius: 0.32rem;
        padding: 0 0.26rem;
      }
    }
  }
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
